.radius_border{border:1px solid #919191;border-radius:5px;}
.custom_typecontrol {
    position:absolute;bottom:20px;right:380px;overflow:hidden;width:132px;height:30px;
    margin:0;padding:0;background-color:#fff;box-shadow:0 1px 3px 0 rgba(0,0,0,.1);border:1px solid rgba(0,0,0,.2);border-radius:3px;z-index:2;}
.custom_typecontrol span {
    display:block;width:65px;height:30px;float:left;cursor:pointer;
    text-align:center;line-height:30px;font-family:'Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana';font-size:12px;line-height:29px;
}
.custom_typecontrol .btn {background:#fff;}
.custom_typecontrol .btn:hover {background:#f5f5f5;}
.custom_typecontrol .btn:active {background:#e6e6e6;background:linear-gradient(#e6e6e6, #fff);}
.custom_typecontrol .selected_btn {color:#fff;background:linear-gradient(#1ba9bb, #26c6da);}
.custom_typecontrol .selected_btn:hover {color:#fff;background:#1ba9bb;}
.custom_etccontrol {
    position:absolute;bottom:130px;right:380px;width:35px;height:31px;overflow:hidden;z-index:2;
    box-shadow:0 1px 3px 0 rgba(0,0,0,0.1);border:1px solid rgba(0,0,0,0.2);border-radius:3px;cursor:pointer;background-color:#fff;}    
.custom_etccontrol .distance{display:block;width:35px;height:31px;font-size:0;
    background-image:url("http://image.medigate.net/drgate/images/location/ico_ruler.png");
    background-position:center 4px;background-size:22px;background-repeat:no-repeat;}

.custom_etccontrol.active {
    position:absolute;bottom:130px;right:380px;width:35px;height:31px;overflow:hidden;z-index:2;
    box-shadow:0 1px 3px 0 rgba(0,0,0,0.1);border:1px solid rgba(0,0,0,0.2);border-radius:3px;cursor:pointer;background-color:#26c6da;}    
.custom_etccontrol.active .distance{display:block;width:35px;height:31px;font-size:0;
    background-image:url("http://image.medigate.net/drgate/images/location/ico_ruler_active.png");
    background-position:center 4px;background-size:22px;background-repeat:no-repeat;}
.custom_etccontrol:hover {background:#e0f7fa;transition:all .2s;}
.custom_etccontrol.active:hover {background:#13aabd;transition:all .2s;}

.custom_zoomcontrol {
    position:absolute;bottom:60px;right:380px;width:35px;height:63px;overflow:hidden;z-index:2;
    box-shadow:0 1px 3px 0 rgba(0,0,0,0.1);border:1px solid rgba(0,0,0,.2);border-radius:3px;}
.custom_zoomcontrol span {float:left;display:block;width:35px;height:31px;text-align:center;cursor:pointer;background-color:#fff;}
.custom_zoomcontrol span:hover {background-color:#f5f5f5;}
.custom_zoomcontrol span.btn1 {
    background-image:url("http://image.medigate.net/drgate/images/location/ico_plus.png");
    background-position:center 9px;background-size:11px;background-repeat:no-repeat;
    border-bottom:1px solid #bfbfbf;}
.custom_zoomcontrol span.btn2 {
    background-image:url("http://image.medigate.net/drgate/images/location/ico_minus.png");
    background-position:center 9px;background-size:11px;background-repeat:no-repeat;}
.custom_zoomcontrol span img {width:15px;height:15px;border:none;}

#category {
    position:absolute;top:92px;left:0;
    border-radius:0 5px 5px 0;border:0;box-shadow:0px 3px 3px -2px rgba(0, 0, 0, 0.1), 0px 5px 7px 3px rgba(0, 0, 0, 0.05);background:#fff;
    overflow:hidden;z-index: 2;}
#category li {
    list-style:none;width:60px;padding:10px 5px;border-bottom:1px solid rgba(0,0,0,0.1);cursor: pointer;
    text-align: center;font-size:11px;color:#808080;line-height:15px;font-family:'Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana';letter-spacing:-1px;}
#category li:hover {background:#e0f7fa;transition:all .2s;}
#category li:first-child{padding:10px 5px 10px 10px}
#category li:last-child{border-bottom:0;padding:10px 10px 10px 5px}
#category li span {display: block;margin:0 auto 3px;width:27px;height: 28px;}
#category li .public{background:url('http://image.medigate.net/drgate/images/location/public.png') center center / 26px no-repeat;}
#category li .bank {background:url('http://image.medigate.net/drgate/images/location/bank.png') center center / 26px no-repeat;}
#category li .mart {background:url('http://image.medigate.net/drgate/images/location/mart.png') center center / 26px no-repeat;}
#category li .pharmacy {background:url('http://image.medigate.net/drgate/images/location/pharmacy.png') center center / 26px no-repeat;}
#category li .edu {background:url('http://image.medigate.net/drgate/images/location/edu.png') center center / 26px no-repeat;}
#category li .school {background:url('http://image.medigate.net/drgate/images/location/school.png') center center / 26px no-repeat;}
#category li .oil {background:url('http://image.medigate.net/drgate/images/location/gas.png') center center / 26px no-repeat;}
#category li.on .pharmacy {background:url('http://image.medigate.net/drgate/images/location/pharmacy-on.png') center center / 26px no-repeat;}
#category li.on .public {background:url('http://image.medigate.net/drgate/images/location/public-on.png') center center / 26px no-repeat;}
#category li.on .bank {background:url('http://image.medigate.net/drgate/images/location/bank-on.png') center center / 26px no-repeat;}
#category li.on .mart {background:url('http://image.medigate.net/drgate/images/location/mart-on.png') center center / 26px no-repeat;}
#category li.on .edu {background:url('http://image.medigate.net/drgate/images/location/edu-on.png') center center / 26px no-repeat;}
#category li.on .school {background:url('http://image.medigate.net/drgate/images/location/school-on.png') center center / 26px no-repeat;}
#category li.on .oil {background:url('http://image.medigate.net/drgate/images/location/gas-on.png') center center / 26px no-repeat;}
#category li.on {background:#26c6da;color:#fff;}
#category li.on:hover {background:#13aabd;transition:all .2s;}

.placeinfo_wrap {position:absolute;bottom:28px;left:-150px;width:300px;}
.placeinfo {position:relative;width:100%;border-radius:6px;border: 1px solid #ccc;border-bottom:2px solid #ddd;padding-bottom: 10px;background: #fff;}
.placeinfo:nth-of-type(n) {border:0; box-shadow:0px 1px 2px #888;}
.placeinfo_wrap .after {content:'';position:relative;margin-left:-12px;left:50%;width:22px;height:12px;background:url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png')}
.placeinfo a, .placeinfo a:hover, .placeinfo a:active{color:#fff;text-decoration: none;}
.placeinfo a, .placeinfo span {display: block;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.placeinfo span {margin:5px 5px 0 10px;cursor: default;font-size:13px;}
.placeinfo .title {font-weight: bold; font-size:14px;border-radius: 6px 6px 0 0;margin: -1px -1px 0 -1px;padding:10px; color: #fff;background: #26c6da;background: #26c6da url(http://image.medigate.net/drgate/images/location/arrow_white.png) no-repeat right 14px center;}
.placeinfo .tel {color:#00acc1;}
.placeinfo .jibun {color:#999;font-size:11px;margin-top:0;}

.emd_name{position:absolute;background:#7a84a7;border:1px solid #41475e;color:#F3F3F3;border-radius:0; font-size:11px; padding:5px;}
#mapParent{position:relative;width:100%;height:100%;}

.overlay_lease{display:block;color:#ff6666;font-size:15px;text-align:center;line-height:20px;}
.overlay_hospital{display:block;color:#546e7a;font-size:13px;text-align:center;line-height:20px;padding:0 5px}

.dot {overflow:hidden;float:left;width:12px;height:12px;background: url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/mini_circle.png');}
.dotOverlay {position:relative;bottom:10px;border-radius:6px;border: 1px solid #ccc;border-bottom:2px solid #ddd;float:left;font-size:12px;padding:5px;background:#fff;}
.dotOverlay:nth-of-type(n) {border:0; box-shadow:0px 1px 2px #888;}
.number {font-weight:bold;color:#ee6152;}
.dotOverlay:after {content:'';position:absolute;margin-left:-6px;left:50%;bottom:-8px;width:11px;height:8px;background:url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white_small.png')}
.distanceInfo {position:relative;top:5px;left:5px;list-style:none;margin:0;}
.distanceInfo .label {display:inline-block;width:50px;}
.distanceInfo:after {content:none;}

.label {margin-bottom: 96px;}
.label * {display: inline-block;vertical-align: top;}
.label .left {background: url("https://t1.daumcdn.net/localimg/localimages/07/2011/map/storeview/tip_l.png") no-repeat;display: inline-block;height: 24px;overflow: hidden;vertical-align: top;width: 7px;}
.label .center {background: url(https://t1.daumcdn.net/localimg/localimages/07/2011/map/storeview/tip_bg.png) repeat-x;display: inline-block;height: 24px;font-size: 12px;line-height: 24px;}
.label .right {background: url("https://t1.daumcdn.net/localimg/localimages/07/2011/map/storeview/tip_r.png") -1px 0  no-repeat;display: inline-block;height: 24px;overflow: hidden;width: 6px;}

.infoWin {margin-bottom: 0px;}
.infoWin * {display: inline-block;vertical-align: top;}
.infoWin .left {background: url("https://t1.daumcdn.net/localimg/localimages/07/2011/map/storeview/tip_l.png") no-repeat;display: inline-block;height: 24px;overflow: hidden;vertical-align: top;width: 7px;}
.infoWin .center {background: url(https://t1.daumcdn.net/localimg/localimages/07/2011/map/storeview/tip_bg.png) repeat-x;display: inline-block;height: 24px;font-size: 12px;line-height: 24px;}
.infoWin .right {background: url("https://t1.daumcdn.net/localimg/localimages/07/2011/map/storeview/tip_r.png") -1px 0  no-repeat;display: inline-block;height: 24px;overflow: hidden;width: 6px;}

.label{margin-bottom:0 !important;width:100%;}
.label .left{background:none;}
.label .center{background:none;font-size:13px;width:100%;text-align:center;display:block;min-width:150px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.label .right{background:none;}

.overlay.cluster{padding:0 15px;border-radius:20.5px;background:#fff;border:3px solid #26c6da;box-shadow:0px 3px 3px -2px rgba(0, 0, 0, 0.1), 0px 5px 7px 3px rgba(0, 0, 0, 0.05);height:41px;}
.overlay.cluster .textinfo{}
.overlay.cluster .textinfo span{display:inline-block;font-size:15px;font-family:'Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana';line-height:21px;height:41px;padding-top:7px}
.overlay.cluster .textinfo .title{color:#333;}
.overlay.cluster .textinfo .num{color:#0c8a9b;padding-left:5px;}

.overlay.lease{padding:0 10px;border-radius:20px;background:#ca191e;border:2px solid #fff;box-shadow:0px 3px 3px -2px rgba(0, 0, 0, 0.1), 0px 5px 7px 3px rgba(0, 0, 0, 0.05);height:34px;}
.overlay.lease .textinfo{}
.overlay.lease .textinfo a{
    display:inline-block;max-width:200px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;
    font-size:13px;font-family:'Noto Sans KR,Dotum,Malgun Gothic,맑은 고딕,돋움,verdana';line-height:30px;color:#fff;}


